<template>
  <div class="container mx-auto p-4">
    <div class="header flex align-center gap-2 mt-2 mb-6 w-full justify-between">
      <img src="@/assets/logo.jpeg" height="64" width="64" class="btn-circle" alt="logo">
      <label class="swap swap-rotate">

        <!-- this hidden checkbox controls the state -->
        <input type="checkbox" class="theme-controller" value="light" />

        <!-- sun icon -->
        <svg class="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>

        <!-- moon icon -->
        <svg class="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>

      </label>
    </div>

    <div class="alert alert-info text-left justify-start fixed bottom-5 mx-auto w-[90%] left-5" v-if="availableConfirm && paymentAllowed"  role="alert">
      <div class="flex flex-col gap-2">
        <span class="text-lg font-semibold">Вы уже готовы заказать?</span>
        <p>После нажатия на кнопку оформления заказа - не уходите от QR-кода, так мы быстрее вас найдем</p>
        <button class="btn ml-0 w-full mt-2"  @click="confirmOrder()">Оформить заказ</button>
      </div>
    </div>


    <div class="flex gap-2 flex-col">
      <h1 class="text-2xl text-primary font-bold">ЗАКАЗ ЕДЫ НА {{place}} МЕСТО</h1>
      <h3>1. ВЫБЕРИТЕ СПОСОБ ОПЛАТЫ</h3>
      <div class="payment-part">
        <div class="flex-col gap-4 flex">
          <label for="money" class="flex gap-2 align-center">
            <input type="radio" id="money" name="payment" value="money" v-model="payment" class="radio" />
            Наличными
          </label>
          <label for="card" class="flex gap-2 align-center">
            <input type="radio" id="card" name="payment" value="card" v-model="payment" class="radio" />
            Картой
          </label>


        </div>

        <input v-model="summ" type="text" inputmode="numeric" @keypress="validate" placeholder="Какой купюрой будете платить?" v-if="payment === 'money'" class="mt-4 input input-bordered w-full max-w-xs" />
      </div>

      <h3>2. ВЫБЕРИТЕ ТОВАР</h3>

      <div class="card-compact dark:bg-neutral rounded mt-4 p-2 shadow-lg" v-for="item in menu" :key="item">
          <img class="rounded object-contain h-80 w-full"  :src="`https://api.городостров.рф${item.image.publicPath}`" alt="">
        <div class="card-body">
          <h2 class="card-title">
            {{item.label}}
          </h2>
          <span v-if="item.available">Цена: {{item.price}} рублей</span>
          <span v-if="!item.available" class="mt-4 w-full bg-error text-error-content text-center rounded font-black text-xl p-2">НЕТ В НАЛИЧИИ</span>
          <div class="card-actions justify-end">
            <button @click="addToCart(item)" v-if="!$store.state.cart.includes(item) && item.available" class="btn btn-primary w-full">Добавить в корзину</button>
            <button @click="removeFromCart(item)"  v-if="$store.state.cart.includes(item)" class="btn btn-error w-full">Убрать из корзины</button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="w-full">
        <div class="card-side p-4 mt-5 bg-neutral rounded shadow-xl flex flex-col gap-5" v-for="i in 10" :key="i">
          <div class="skeleton h-32 w-full"></div>
          <div class="skeleton h-6 w-full"></div>
          <div class="skeleton h-4 w-full"></div>
          <div class="skeleton h-4 w-full"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src



export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return {
      menu : [],
      availableConfirm : false,
      place : 0,
      payment : false,
      summ : null,
      loading : true,
      paymentAllowed: false,
      finalPayment : null,
    }
  },
  watch : {
    summ(news){
      this.finalPayment = 'money:'+news;
      if(news > 0){
        this.paymentAllowed = true;
      }
    },
    payment(news){
      this.paymentAllowed = false
      if(news == 'card'){
        this.paymentAllowed = true;
        this.finalPayment = 'card'
      }
    }
  },
  async mounted(){

    let f = await (await fetch("https://api.городостров.рф/api/v1/itnight/menu")).json()
    this.menu = f;
    this.loading = false;

    this.place = atob(this.$route.params.hash).split(':')[1];

  },
  methods : {
    async confirmOrder(){
      let formData = new FormData();
      formData.append('cart',JSON.stringify(this.$store.state.cart));
      formData.append('payment',this.finalPayment);

     await (await fetch("https://api.городостров.рф/api/v1/itnight/order/" + this.place, {
        method : "post",
        body : formData
      })).json();
      alert("Заказ сделан");
    },
    validate(evt) {
      var theEvent = evt || window.event;
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode( key );
      var regex = /[0-9]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    addToCart(item){
      this.$store.state.cart.push(item);
      this.availableConfirm = true
    },
    removeFromCart(item){
      this.$store.state.cart.splice(this.$store.state.cart.indexOf(item),1);
      if(this.$store.state.cart.length > 0){
        this.availableConfirm = true
      }
      else{
        this.availableConfirm = false
      }
    }
  }
}
</script>
<style scoped>
</style>
