<template>
  <div class="home">
    <Imagine></Imagine>
  </div>
</template>

<script>
// @ is an alias to /src

import Imagine from "@/components/Imagine";


export default {
  name: 'HomeView',
  components: {
    Imagine

  }
}
</script>
<style scoped>
  .home{
    height: 100vh;

    width: 100vw;
    position: relative;
  }
</style>
