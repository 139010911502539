<template>
  <div id="social"></div>

  <div id="scene">
    <div id="plane" class="pl1"></div>
    <div id="plane2" class="pl2"></div>

    <div class='cube cube_roof'>
      <div class='face front'></div>
      <div class='face side'></div>
      <div class='face top'></div>
    </div>
    <div class='cube cube_roof2'>
      <div class='face front'></div>
      <div class='face side'></div>
      <div class='face top'></div>
    </div>
    <div class='cube' id='building'>
      <div class='face front'>
        <div class='sideScroll ss1'>
          <div class="text">15.05.2024 IT-НочЬ</div>
        </div>
        <div class="entrance entrance2"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:25px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:47.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:70px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:92.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:115px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:137.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:160px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:182.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:205px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:0px; top:227.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:25px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:47.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:70px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:92.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:115px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:137.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:160px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:182.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:205px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:30px; top:227.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:25px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:47.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:70px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:92.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:115px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:137.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:160px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:182.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:205px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:60px; top:227.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:25px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:47.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:70px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:92.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:115px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:137.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:160px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:182.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:205px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:90px; top:227.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:25px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:47.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:70px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:92.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:115px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:137.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:160px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:182.5px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:205px;"></div>
        <div class="window" style="height:22.5px; width:30px; left:120px; top:227.5px;"></div>
      </div>
      <div class='face side'>
        <div class='sideScroll ss2'>
          <div class="text">15.05.2024 IT-НочЬ</div>
        </div>
        <div class="entrance">
          <div class="ent ent1"></div>
          <div class="ent ent2"></div>
        </div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:25px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:47.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:70px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:92.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:115px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:137.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:160px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:182.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:205px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:0px; left:227.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:25px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:47.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:70px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:92.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:115px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:137.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:160px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:182.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:205px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:30px; left:227.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:25px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:47.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:70px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:92.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:115px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:137.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:160px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:182.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:205px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:60px; left:227.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:25px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:47.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:70px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:92.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:115px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:137.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:160px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:182.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:205px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:90px; left:227.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:25px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:47.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:70px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:92.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:115px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:137.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:160px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:182.5px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:205px;"></div>
        <div class="window" style="width:22.5px; height:30px; top:120px; left:227.5px;"></div>
      </div>
      <div class='face top'>
      </div>
      <div class="sign_wrap sign_wrap1">
        <div class='sign sign1'>
          <span class="sign_letter sl1">爱</span>
          <span class="sign_letter sl2">餐</span>
          <span class="sign_letter sl4">饮</span>
        </div>
      </div>
      <div class="sign_wrap sign_wrap2">
        <div class='sign sign2'>
          <span class="sign_letter sl4">15</span>
          <span class="sign_letter sl2">05</span>
          <span class="sign_letter sl3">24</span>
        </div>
      </div>
      <div class="sign_wrap sign_wrap3">
        <div class='sign sign3'>IT-НочЬ</div>
      </div>
      <div class="stand"></div>
      <div class="track track2"></div>
      <div class="car">
        <div class="car_body">
          <div class="car_frontlight"></div>
          <div class="car_backlight"></div>
        </div>
        <div class="car_roof"></div>
      </div>
      <div class="car car2">
        <div class="car_body">
          <div class="car_frontlight"></div>
          <div class="car_backlight"></div>
        </div>
        <div class="car_roof"></div>
      </div>
      <div class="car car3">
        <div class="car_body">
          <div class="car_frontlight"></div>
          <div class="car_backlight"></div>
        </div>
        <div class="car_roof"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imagine-view"
}
</script>

<style scoped>
*{
  font-family: 'Arial', cursive;
}


#scene{
  position: fixed;
  left: 55%;
  bottom: -8%;
  transform: translate(-50%, -50%) scale(0.6);
  z-index: 2;

  margin-top: 200px;
}

#plane{
  height: 500px;
  width: 500px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 50%) rotate(210deg) skew(-30deg);
  background: #232323;
  z-index: -1;
}
#plane2{
  height: 500px;
  width: 500px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 50%) rotate(210deg) skew(-30deg);
  background: #232323;
  z-index: -1;
}
.pl2{
  margin-top: 50px !important;
  margin-left: 88px !important;
  height: 500px !important;
  width: 70px !important;
  background: #141414 !important;
  z-index: 2 !important;
}
.pl2::after{
  content: " ";
  height: 100%;
  width: 50%;
  position: absolute;
  border-right: 6px dashed #fff;
}

/* ===== Isometric ===== */
.cube{
  width: 300px;
  height: 300px;
  overflow: hidden;
}
.face{
  height: 150px;
  width: 150px;
  transform-origin: 0 0;
  position: absolute;
}
.front{
  height: 300px;
  background: #282828;
  -ms-transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
  -webkit-transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
  transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
}
.top{
  background: #424242;
  -ms-transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
  -webkit-transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
  transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
}
.side{
  width: 300px;
  background: #343434;
  -ms-transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
  -webkit-transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
  transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
}
/* ===== Isometric END ===== */

.window{
  box-sizing: border-box;
  border: 0.5px solid rgba(30,30,30,0.5);
  position: absolute;
  background: radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0));
}

.sign_wrap{
  top: 50px;
  left: 0px;
  text-orientation: upright;
  writing-mode: vertical-lr;
  transform-origin: 0 0;
  position: absolute;
  transform: rotate(90deg) skewX(-30deg) translate(68px, 0px) scaleY(0.864);
  /*transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);*/
}
.sign_wrap2{
  top: 110px;
  left: 130px;
  transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
}
.sign_wrap3{
  top: 165px;
  left: 50px;
  transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
}
.sign{
  transform: rotateZ(-90deg);
  padding: 5px 2px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  user-select: none;
  pointer-events: none;
  color: red;
  border: 3px solid red;
  filter: drop-shadow(0px 0px 20px red);
  box-shadow: 0px 0px 10px red, inset 0px 0px 10px red;
  text-shadow: 0px 0px 10px red;
}
.sign2{
  transform: rotateZ(0deg);
  color: #fc3aeb;
  border: 3px solid #fc3aeb;
  filter: drop-shadow(0px 0px 20px #fc3aeb);
  box-shadow: 0px 0px 10px #fc3aeb;
  text-shadow: 0px 0px 10px #fc3aeb;
}
.sign3{
  text-orientation: sideways;
  transform: rotateZ(-90deg);
  color: yellow;
  border: 3px solid yellow;
  filter: drop-shadow(0px 0px 20px yellow);
  box-shadow: 0px 0px 10px yellow;
  text-shadow: 0px 0px 10px yellow;
}
.sideScroll{
  background: #f44242;
  filter: drop-shadow(0px 0px 20px #42d7f4);
  /*box-shadow: 0px 0px 20px rgba(255, 50, 255, 1);*/
  position: relative;
  left: 0px;
  top: 0px;
  height: 25px;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  color: #fff;
  font-weight: 600;
  text-shadow: 0px 0px 5px #000;
}

.ss2{
  height: 100%;
  width: 25px;
}

.sideScroll > .text{
  position: absolute;
  animation: scrollText 20s linear infinite;
  animation-delay: 3870ms;
  left: -100%;
  top: 2px;
  width: 100%;
  white-space: nowrap;
}

.ss2 > .text{
  transform: rotateZ(270deg);
  position: absolute;
  animation: scrollText2 20s linear infinite;
  animation-delay: 0ms;
  top: 200%;
  left: 0px;
  width: 100%;
  white-space: nowrap;
}

@keyframes scrollText{
  0%{
    left: -100%;
  }
  50%{
    left: 200%;
  }
  100%{
    left: 200%;
  }
}
@keyframes scrollText2{
  0%{
    top: 200%;
  }
  50%{
    top: -100%;
  }
  100%{
    top: -100%;
  }
}

.cube_roof{
  position: absolute;
  z-index: 4;
  top: -100px;
  left: 0px;
}
.cube_roof > .face{
  height: 50px;
  width: 50px;
}
.cube_roof > .top{
  background: #454545;
}

.cube_roof2{
  position: absolute;
  z-index: 4;
  top: -222px;
  left: 0px;
}
.cube_roof2 > .face{
  height: 6px;
  width: 6px;
}
.cube_roof2 > .front{
  height: 100px;
}
.cube_roof2 > .side{
  width: 100px;
}

.entrance{
  position: absolute;
  height: 40px;
  width: 30px;
  top: 50%;
  right: 0px;
  transform: translate(0%, -50%);
  background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));
}
.entrance2{
  top: 300px;
  left: 50%;
  transform: translate(-40%, -100%);
  height: 20px;
  width: 80px;
}

.ent{
  height: 20px;
  width: 30px;
  border: 1px solid rgba(30,30,30,0.5);
  position: relative;
  top: 0px;
  right: 0px;
}
.ent2{
  top: -1px;
}

.stand{
  position: absolute;
  top: 335px;
  left: 140px;
  background: #3a3a3a;
  height: 50px;
  width: 100px;
  transform: rotateZ(-30deg) rotateX(150deg) rotateY(0deg) skewX(-10deg) scaleY(0.864);
  z-index: 4;
}

.track{
  height: 2000px;
  width: 20px;
  background: linear-gradient(to right, rgba(98,255,50,0) 0%,rgba(98,255,50,1) 18%,rgba(98,255,50,0) 36%,rgba(98,255,50,0) 64%,rgba(98,255,50,1) 82%,rgba(98,255,50,0) 100%);
  filter: drop-shadow(0px 0px 20px rgba(100,255,100,1));
  transform: scaleX(-1) rotateX(0deg) rotateY(0deg) rotateZ(270deg) skewX(-30deg) scaleY(0.864);
  position: absolute;
  top: -852px;
  left: -25px;
}

.track2{
  left: 234px;
}

.car{
  transform: rotate(330deg) skewX(-30deg) scaleY(0.864);
  position: absolute;
  animation-delay: 0s;
  animation: carMove 10s linear infinite;
  top: -55px;
  right: -300px;
}
.car2{
  animation-delay: 4s !important;
  animation: carMove 10s linear infinite;
}
.car_roof{
  position: absolute;
  height: 15px;
  width: 30px;
  top: -13px;
  left: 10px;
  background: #8e8e8e;
  transform: perspective(30px) rotateX(45deg);
}
.car_body{
  position: relative;
  width: 50px;
  height: 10px;
  background: #6e6e6e;
}

.car_frontlight{
  position: absolute;
  left: 0px;
  top: 0px;
  height: 5px;
  width: 5px;
  background: yellow;
  filter: drop-shadow(0px 0px 20px yellow);
}

.car_backlight{
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 10px;
  width: 3px;
  background: red;
  filter: drop-shadow(0px 0px 20px red);
}

@keyframes carMove{
  0%{
    top: -55px;
    right: -300px;
  }
  100%{
    top: 640px;
    right: 900px;
  }
}

.window:nth-child(4){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(12){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(6){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(23){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(2){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0)); animation: light_switch 9s ease-in-out infinite;}
.window:nth-child(18){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0)); animation: light_switch 4s ease-in-out infinite;}
.window:nth-child(36){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(31){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0)); animation: light_switch 7s ease-in-out infinite;}
.window:nth-child(28){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(40){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));}
.window:nth-child(33){background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0)); animation: light_switch 5s ease-in-out infinite;}

@keyframes light_switch{
  0%{
    background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));
  }
  50%{
    background: radial-gradient(rgba(255,255,0,0), rgba(0,0,0,0));
  }
  100%{
    background: radial-gradient(rgba(255,255,0,0.3), rgba(0,0,0,0));
  }
}

.sign_letter{
  margin-top: -10px;
  font-weight: 600;

}
.sign1,
.sign2{
  padding-top: 15px;
}

.sl1{animation: flicker_v1 5s linear infinite;}
.sl2{animation: flicker_v3 7s linear infinite;}
.sl3{animation: flicker_v2 4s linear infinite;}
.sl4{animation: flicker_v4 4s linear infinite;}

@keyframes flicker_v1{
  0%  {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  30% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  31% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  36% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  37% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  41% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  42% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  45% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  46% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  100%{
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
}

@keyframes flicker_v2{
  0%  {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  30% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  31% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  32% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  33% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  41% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  42% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  43% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  44% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  50% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  51% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  54% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  55% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  100%{
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
}

@keyframes flicker_v3{
  0%  {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  60% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  61% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  66% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  67% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  100%{
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
}

@keyframes flicker_v4{
  0%  {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  35% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  36% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  41% {
    color: #000;
    text-shadow: 0px 0px 0px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  42% {
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
  100%{
    color: var(--light_col);
    text-shadow: 0px 0px 20px var(--light_col), -1px 0 var(--letter_border), 0 1px var(--letter_border), 1px 0 var(--letter_border), 0 -1px var(--letter_border);
  }
}

@keyframes pulse{
  0%  {box-shadow: 0px 0px 10px var(--light_col), inset 0px 0px 5px var(--light_col);}
  50% {box-shadow: 0px 0px 30px var(--light_col), inset 0px 0px 20px var(--light_col);}
  100%{box-shadow: 0px 0px 10px var(--light_col), inset 0px 0px 5px var(--light_col);}
}
</style>